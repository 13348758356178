import { Box, HStack } from "@chakra-ui/react";
import React, { ReactElement, ReactNode } from "react";

interface SidebarContainerProps {
  sidebar: ReactNode;
  children: ReactNode;
}

export default function SidebarContainer(
  props: SidebarContainerProps
): ReactElement {
  const { sidebar, children } = props;

  return (
    <HStack
      flexDirection={["column", "column", "row"]}
      spacing={["0", "0", "8"]}
      justifyContent={["flex-start", "flex-start", "stretch"]}
      alignItems="flex-start"
      w="100%"
    >
      <Box flexGrow={1}>{children}</Box>
      <Box maxW="200px">{sidebar}</Box>
    </HStack>
  );
}
