import React, { ReactElement } from "react";
import Heading from "./Heading";
import Para from "./Para";

interface CourseLogisticsProps {
  category: "scala" | "typescript";
}

export default function CourseLogistics(
  props: CourseLogisticsProps
): ReactElement {
  const { category: _category } = props;

  return (
    <>
      <Heading level="section">Logistics</Heading>

      <Para>
        We can deliver courses on-site or online over video conferencing in the
        UK, Europe, US, or further afield.
      </Para>

      <Para>
        We&apos;ll adjust the teaching schedule to a certain degree to fit
        around your team&apos;s existing commitments. When teaching online we
        recommend splitting longer courses into shorter sessions with
        interspersed breaks to avoid fatigue.
      </Para>
    </>
  );
}
