import { checkExhausted } from "@/util";
import { Badge, Flex, List, ListItem, StackProps } from "@chakra-ui/react";
import React, { ReactElement, useCallback } from "react";
import { useCookies } from "react-cookie";

const gbpDayRate = 2500;
const usdDayRate = 3500;
const eurDayRate = 3000;

type Currency = "gbp" | "usd" | "eur";

export interface CourseBadgesProps extends StackProps {
  days: number;
  hours?: number;
  capacity?: number;
  gbpPrice?: number;
  usdPrice?: number;
  eurPrice?: number;
}

function useCurrencyCookie(
  defaultCurrency: Currency
): [Currency, (currency: Currency) => void] {
  const [cookies, setCookie, _removeCookie] = useCookies(["currency"]);

  const currency: Currency = cookies.currency ?? defaultCurrency;

  const setCurrency = useCallback(
    (curr: Currency) => setCookie("currency", curr),
    [setCookie]
  );

  return [currency, setCurrency];
}

export default function CourseBadges(props: CourseBadgesProps): ReactElement {
  const {
    days,
    hours = days * 8,
    capacity = 10,
    gbpPrice = days * gbpDayRate,
    usdPrice = days * usdDayRate,
    eurPrice = days * eurDayRate,
    ...rest
  } = props;

  const [currency, setCurrency] = useCurrencyCookie("gbp");

  const toggleCurrency = useCallback(() => {
    switch (currency) {
      case "gbp":
        setCurrency("usd");
        break;
      case "usd":
        setCurrency("eur");
        break;
      case "eur":
        setCurrency("gbp");
        break;
      default:
        checkExhausted(currency);
    }
  }, [currency, setCurrency]);

  let symbol: string;
  let price: string;
  switch (currency) {
    case "gbp":
      symbol = "£";
      price = gbpPrice.toLocaleString();
      break;
    case "usd":
      symbol = "$";
      price = usdPrice.toLocaleString();
      break;
    case "eur":
      symbol = "€";
      price = eurPrice.toLocaleString();
      break;
    default:
      checkExhausted(currency);
  }

  return (
    <Flex as={List} flexWrap="wrap" mb="6" {...rest}>
      <ListItem display="inline-block" mr="2" my="2">
        <Badge
          colorScheme="blackAlpha"
          transitionDuration=".25s"
          _hover={{ bg: "blackAlpha.300", cursor: "pointer" }}
          onClick={toggleCurrency}
          title="Click to toggle currency"
        >
          {symbol}
          {price}
        </Badge>
      </ListItem>
      <ListItem display="inline-block" mr="2" my="2">
        <Badge
          colorScheme="blackAlpha"
          title={`${hours} hours of content if delivered online`}
        >
          {days} days
        </Badge>
      </ListItem>
      <ListItem display="inline-block" mr="2" my="2">
        <Badge colorScheme="blackAlpha">2-{capacity} trainees</Badge>
      </ListItem>
      <ListItem display="inline-block" mr="2" my="2">
        <Badge colorScheme="blackAlpha">On-site or remote</Badge>
      </ListItem>
    </Flex>
  );
}
