import useAccent from "@/hooks/useAccent";
import { Accent } from "@/theme";
import {
  Button as ChakraButton,
  ButtonProps as ChakraButtonProps,
  SystemStyleObject,
} from "@chakra-ui/react";
import React, { ReactElement, ReactNode } from "react";

export interface ButtonProps extends ChakraButtonProps {
  accent?: Accent;
  children: ReactNode;
}

type Variant = ChakraButtonProps["variant"];
type PR<A extends string, B> = Partial<Record<A, B>>;

export const buttonOverrides: PR<Variant, PR<Accent, SystemStyleObject>> = {
  solid: {
    purple: {
      bg: "purple.300",
    },
  },
  outline: {
    purple: {
      color: "purple.300",
      borderColor: "purple.300",
    },
    orange: {
      color: "orange.400",
      borderColor: "orange.400",
    },
    pink: {
      color: "pink.400",
      borderColor: "pink.400",
    },
    red: {
      color: "red.500",
      borderColor: "red.500",
    },
  },
};

export default function Button(props: ButtonProps): ReactElement {
  const { accent, variant, size, sx, children, ...rest } = props;

  const defaultAccent = useAccent();
  const colorScheme = accent ?? defaultAccent;
  const allSx = {
    ...(sx ?? {}),
    ...(buttonOverrides?.[variant]?.[colorScheme] ?? {}),
  };

  return (
    <ChakraButton
      colorScheme={accent ?? defaultAccent}
      variant={variant}
      size={size}
      sx={allSx}
      {...rest}
    >
      {children}
    </ChakraButton>
  );
}
