import { Stack } from "@chakra-ui/react";
import React, { MouseEvent, ReactElement, ReactNode, useMemo } from "react";
import { Accent } from "../theme";
import CourseLogistics from "./CourseLogistics";
import Heading from "./Heading";
import LinkButton from "./LinkButton";
import SidebarContainer from "./SidebarContainer";
import SpacePage from "./SpacePage";
import SpaceSection from "./SpaceSection";

export interface CoursePageProps {
  title: string;
  category: "scala" | "typescript";
  accent: Accent;
  abstract: ReactNode;
  bookingLink?: string;
  bookingForm?: ReactNode;
  picture: ReactNode;
  outline: ReactNode;
  sidebar?: ReactNode;
}

const bookNowHeadingId = "book-now";
const bookNowHeadingHref = `#${bookNowHeadingId}`;

export default function CoursePage(props: CoursePageProps): ReactElement {
  const {
    title,
    category,
    accent,
    abstract,
    bookingLink = bookNowHeadingHref,
    bookingForm,
    picture,
    outline,
    sidebar,
  } = props;

  const handleBookNowClick = useMemo(
    () =>
      bookingLink === bookNowHeadingHref
        ? (evt: MouseEvent<HTMLButtonElement>) => {
            const heading = document.getElementById(bookNowHeadingId);

            if (heading != null) {
              evt.preventDefault();
              window.scrollTo({ top: heading.offsetTop, behavior: "smooth" });
            }
          }
        : undefined,
    [bookingLink]
  );

  return (
    <SpacePage title={title} accent={accent} activeMenuItem="training">
      <SpaceSection bg="beige" pt="16" pb="16">
        <Heading level="page">{title}</Heading>
        <Stack
          spacing="16"
          flexDirection={["column", "column", "row"]}
          alignItems="center"
        >
          <Stack
            flexDirection="column"
            alignItems={["center", "center", "flex-start"]}
          >
            {abstract}
            <LinkButton to={bookingLink} onClick={handleBookNowClick}>
              Book Now
            </LinkButton>
          </Stack>
          {picture}
        </Stack>
      </SpaceSection>
      <SpaceSection pt="24" pb="32" ct="lg" cb="sm" minH="30em">
        <Heading level="section">Course Outline</Heading>
        {sidebar == null ? (
          outline
        ) : (
          <SidebarContainer sidebar={sidebar}>{outline}</SidebarContainer>
        )}
      </SpaceSection>
      {bookingForm && (
        <SpaceSection id={bookNowHeadingId} bg="beige" pt="12" pb="4">
          <Heading level="section">Book Now</Heading>
          {bookingForm}
        </SpaceSection>
      )}
      <SpaceSection bg="beige" pt="4" pb="32">
        <CourseLogistics category={category} />
      </SpaceSection>
    </SpacePage>
  );
}
