/** @jsx jsx */

import { css } from "@chakra-ui/react";
import { jsx } from "@emotion/react";
import { ReactElement } from "react";

export interface HeadlessReformProps {
  children: ReactNode;
}

const styles = css`
  margin: auto;
  max-width: 50ch;
`;

export default function HeadlessReform(
  props: HeadlessReformProps
): ReactElement {
  const { children } = props;

  return (
    <div className="headless-reform" css={styles}>
      {children}
    </div>
  );
}
