import Button from "@/components/Button";
import HeadlessReform from "@/components/HeadlessReform";
import {
  FormControl,
  FormLabel,
  Input,
  Radio,
  RadioGroup,
  Textarea,
  VStack,
} from "@chakra-ui/react";
import React, { ReactElement } from "react";

const courses = {
  "essential-scala": "Essential Scala",
  "scala-with-cats": "Scala with Cats",
  custom: "Custom (special requirements or unsure)",
};

type CourseId = keyof typeof courses;

const sizes = {
  "2-to-5": "2 to 5 people",
  "5-to-10": "5 to 10 people",
  "11-or-more": "11 or more people ",
};

type SizeId = keyof typeof sizes;

export interface BookingFormProps {
  defaultCourse?: CourseId;
  defaultSize?: SizeId;
  showCourseSelect?: boolean;
}

export default function BookingForm(props: BookingFormProps): ReactElement {
  const {
    defaultCourse,
    defaultSize = "5-to-10",
    showCourseSelect = defaultCourse == null,
  } = props;

  return (
    <HeadlessReform>
      <form
        method="POST"
        action="https://forms.reform.app/headless/spacecatio/training-enquiries/c90b2k/submissions"
      >
        <VStack align="flex-start" spacing="8">
          <FormControl>
            <FormLabel>Your Name</FormLabel>
            <Input
              type="text"
              id="3f3daffe-bf8c-4bf3-918f-b16283c704d8"
              name="answers[3f3daffe-bf8c-4bf3-918f-b16283c704d8]"
              placeholder="Jane Doe"
              required
            />
          </FormControl>
          <FormControl>
            <FormLabel>Your Email Address</FormLabel>
            <Input
              type="email"
              id="b43be548-3310-4521-84cf-aeb4fb18398d"
              name="answers[b43be548-3310-4521-84cf-aeb4fb18398d]"
              placeholder="jane@example.com"
              required
            />
          </FormControl>
          <FormControl>
            <FormLabel>Your Phone Number (Optional)</FormLabel>
            <Input
              type="text"
              id="dee7d2f9-b846-420f-a049-dad247720c82"
              name="answers[dee7d2f9-b846-420f-a049-dad247720c82]"
              placeholder="+1 (123) 456 678"
            />
          </FormControl>
          <FormControl>
            <FormLabel>Team Size</FormLabel>
            <RadioGroup defaultValue={sizes[defaultSize]}>
              <VStack align="flex-start">
                {Object.values(sizes).map(size => (
                  <Radio
                    type="radio"
                    id="5cdc8f6e-119a-40f6-a342-75d5e60fdad2"
                    name="answers[ea998951-faa9-424c-b0fb-063e44b3ed3c]"
                    value={size}
                    required
                  >
                    {size}
                  </Radio>
                ))}
              </VStack>
            </RadioGroup>
          </FormControl>
          <FormControl>
            <FormLabel>Where Are You Located?</FormLabel>
            <Input
              type="text"
              id="d0da81af-fb24-4523-aba2-bff5b1d027b2"
              name="answers[d0da81af-fb24-4523-aba2-bff5b1d027b2]"
              placeholder="Secret Lab, Antarctica"
              required
            />
          </FormControl>
          <FormControl display={showCourseSelect ? undefined : "none"}>
            <FormLabel>Which Course(s) are you Interested In?</FormLabel>
            <RadioGroup
              defaultValue={
                defaultCourse == null ? undefined : courses[defaultCourse]
              }
            >
              <VStack align="flex-start">
                {Object.values(courses).map(course => (
                  <Radio
                    id="24dcf520-afe5-41c1-80a7-a58791162dd7"
                    name="answers[7d97fe57-8564-4f31-8cb1-cef5453b16a0]"
                    value={course}
                    required
                  >
                    {course}
                  </Radio>
                ))}
              </VStack>
            </RadioGroup>
          </FormControl>
          <FormControl>
            <FormLabel>Additional Information</FormLabel>
            <Textarea
              id="310f45b0-ca41-4a17-bf61-98a466e8588e"
              name="answers[310f45b0-ca41-4a17-bf61-98a466e8588e]"
              placeholder="Level of experience, current software stack, etc..."
            />
          </FormControl>
          <Button type="submit">Submit</Button>
        </VStack>
      </form>
    </HeadlessReform>
  );
}
