import useAccent from "@/hooks/useAccent";
import { Target, useLinkTarget } from "@/hooks/useLinkTarget";
import { Button, ButtonProps, LinkOverlay } from "@chakra-ui/react";
import React, { ReactElement, ReactNode } from "react";
import { Accent } from "../theme";
import { buttonOverrides } from "./Button";

export interface LinkButtonProps extends ButtonProps {
  accent?: Accent;
  to: string;
  target?: Target;
  "data-savvycal-embed"?: boolean;
  children: ReactNode;
}

export default function LinkButton(props: LinkButtonProps): ReactElement {
  const {
    accent,
    variant = "solid",
    size = "md",
    to,
    target: _target,
    "data-savvycal-embed": dataSavvyCalEmbed,
    children,
    ...rest
  } = props;

  const defaultAccent = useAccent();
  const colorScheme = accent ?? defaultAccent;
  const sx = buttonOverrides?.[variant]?.[colorScheme] ?? {};

  const [target, rel, icon] = useLinkTarget(to, _target);

  return (
    <Button
      colorScheme={colorScheme}
      variant={variant}
      size={size}
      sx={sx}
      {...rest}
    >
      <LinkOverlay
        href={to}
        data-savvycal-embed={dataSavvyCalEmbed}
        target={target}
        rel={rel}
      >
        {children}
        {icon}
      </LinkOverlay>
    </Button>
  );
}
