import useAccent from "@/hooks/useAccent";
import { Image, ImageProps } from "@chakra-ui/react";
import React, { ReactElement } from "react";
import { Accent } from "../theme";

export type Name =
  | "spacecat"
  | "saucer"
  | "rocket"
  | "astronaut"
  | "box"
  | "telly"
  | "tumble1"
  | "tumble2"
  | "scala-logos"
  | "typescript-logos";

type TrimmedImageProps = Omit<ImageProps, "src">;

export interface SpacePicProps extends TrimmedImageProps {
  name: Name;
  accent?: Accent;
}

export default function SpacePic(props: SpacePicProps): ReactElement {
  const { name, accent, ...rest } = props;

  const defaultAccent = useAccent();

  const src = `/images/spacepics/${name}-${accent ?? defaultAccent}.svg`;

  return <Image src={src} {...rest} />;
}
